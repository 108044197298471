import { createRouter, createWebHistory } from "vue-router";
import { useGlobalStore, useUserStore } from "@/store";

/** Layouts */
import MainLayout from "../layouts/MainLayout.vue";
import AuthLayoutVue from "@/layouts/AuthLayout.vue";

/** Views */
import LogoutView from "@/views/LogoutView.vue";
import RoleSelectView from "@/views/RoleSelectView.vue";

import DashboardView from "@/views/DashboardView.vue";
import WebsiteData from "@/views/WebsiteData.vue";

import ErrorPage from "@/views/ErrorPage.vue";
import AcceptTerms from "@/views/orders/AcceptTerms.vue";

import SurveyIndex from "@/views/feedback/SurveyIndex.vue";
import SurveyPage from "@/views/feedback/SurveyPage.vue";

import MemberOutcomesIndex from "@/views/member_outcomes/MemberOutcomesIndex.vue";
import MemberOutcomePage from "@/views/member_outcomes/MemberOutcomePage.vue";

// TODO: Sort this out
import ClubFeedbackPage from "@/views/surveys/SurveyIndex.vue";

import { userRoutes } from "./users";
import { courseRoutes } from "./courses";
import { clubRoutes } from "./clubs";
import { resourceRoutes } from "./resources";
import { profileRoutes } from "./profile";
import { authRoutes } from "./auth";
import { orderRoutes } from "./orders";
import { discountRoutes } from "./discounts";
import { parentRoutes } from "./parents";
import { storeToRefs } from "pinia";
import { userHasRole } from "@/utils/roles";

const routes = [
  {
    path: "/",
    component: AuthLayoutVue,
    children: authRoutes,
  },
  {
    path: "/dashboard",
    name: "DashboardLayout",
    component: MainLayout,
    children: [
      ...discountRoutes,
      ...resourceRoutes,
      ...profileRoutes,
      ...parentRoutes,
      ...courseRoutes,
      ...orderRoutes,
      ...clubRoutes,
      ...userRoutes,
      {
        path: "/dashboard/",
        name: "Dashboard",

        component: DashboardView,
        meta: {
          name: "Dashboard",
          breadcrumbs: ["Dashboard"],
        },
      },
      {
        path: "/dashboard/onboarding",
        name: "Onboarding",
        component: RoleSelectView,
        meta: {
          name: "Select Account Type",
          breadcrumbs: ["Dashboard", "Onboarding"],
        },
      },
      {
        path: "/dashboard/websitedata/",
        name: "Website Data",
        component: WebsiteData,
        meta: {
          name: "Website Data",
          breadcrumbs: ["Dashboard", "Website Data"],
        },
      },
      {
        path: "/dashboard/actions/:id",
        name: "Course_Prerequisites",

        component: AcceptTerms,
        meta: {
          name: "Course Prerequisites",
          breadcrumbs: ["Dashboard", "Course_Prerequisites"],
        },
      },

      {
        path: "/dashboard/training-feedback",
        name: "Training_Feedback",
        component: SurveyIndex,
        meta: {
          name: "Training Feedback",
          breadcrumbs: ["Dashboard", "Training_Feedback"],
        },
      },

      {
        path: "/dashboard/training-feedback/:id",
        name: "Survey",
        component: SurveyPage,
        meta: {
          name: "Survey",
          breadcrumbs: ["Dashboard", "Training_Feedback", "Survey"],
        },
      },
      {
        path: `/dashboard/club-member-outcomes`,
        name: "Member_Outcomes",
        component: MemberOutcomesIndex,
        meta: {
          name: "Member Outcomes",
          breadcrumbs: ["Dashboard", "Member_Outcomes"],
        },
      },

      {
        path: "/dashboard/club-member-outcomes/:member_id",
        name: "Member_Outcome",
        component: MemberOutcomePage,
        meta: {
          name: "Member Outcome",
          breadcrumbs: ["Dashboard", "Member_Outcomes", "Member_Outcome"],
        },
      },

      {
        path: "/dashboard/member-outcomes",
        name: "Member_Outcomes_Rename",
        component: SurveyIndex,
        meta: {
          name: "Member Outcomes",
          breadcrumbs: ["Dashboard", "Member_Outcomes"],
        },
      },
      {
        path: "/dashboard/member-outcomes/:id",
        name: "Club_Feedback",
        component: ClubFeedbackPage,
        meta: {
          name: "Club Feedback",
          breadcrumbs: ["Dashboard", "Member_Outcomes", "Club_Feedback"],
        },
      },

      {
        path: "/logout",
        name: "Logout",
        component: LogoutView,
        meta: {
          name: "Logout",
        },
      },
      { path: "/error", name: "Error", component: ErrorPage, meta: { name: "Error" } },
      { path: "/:catchAll(.*)", redirect: { name: "Error" } },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async to => {
  const { isUserLoading, isUserOnboarded, isUserSubscribed, user } = useUserStore();
  const { isMobileMenuOpen } = storeToRefs(useGlobalStore());

  if (isMobileMenuOpen) {
    isMobileMenuOpen.value = false;
  }

  const allowedRoutes = ["/login", "/signup", "/logout", "/dashboard/onboarding"];

  try {
    if (!isUserLoading && !isUserOnboarded && !allowedRoutes.includes(to.fullPath)) {
      return { name: "Onboarding" };
    }

    /** Prevent onboarded users from returning to Onboarding screen */
    if (!isUserLoading && to.name === "Onboarding" && isUserOnboarded) {
      return { name: "Dashboard" };
    }

    /** Prevent lower roles from accessing events list */
    // Redirect to courses page if user is not an admin or trainer
    if (
      userHasRole(user.roles, ["parent", "brick-club-family", "professional", "certified-facilitator-trainee", "certified-facilitator", "advanced-facilitator-trainee", "advanced-facilitator"]) &&
      to.name === "Training_Events"
    ) {
      return { name: "Courses" };
    }
  } catch (err) {
    console.log({ err });
  }

  document.title = `${to.meta.name} | Play Included Admin`;
});

export default router;
